import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../layouts/zh";
import Reward from "../../services/RewardsDetail";
import langData from "../../data/static-pages/pages/rewards/zh";
import { IN_PROGRESS } from "../../state/constants/actions";

const RewardDetailPage = ({ ...props }) => {
  const { uiState } = useSelector((state) => state.reward);

  return (
    <Layout location={props.location} footer_margin={uiState === IN_PROGRESS}>
      <Reward
        baseUrl={props.location.origin}
        params={props.location.search}
        langKey={props.pageContext.lang}
        pathname={props.location.pathname}
        slug={props.pageContext.slug}
        landingUrl={"/zh/reward"}
        loginUrl={"/zh/login"}
        langData={langData}
      />
    </Layout>
  );
};

export default RewardDetailPage;
